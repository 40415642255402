import { LazyImage } from "../Styled/Elements"
import { Banner1, ExpertiseP1, ExpertiseP2, ExpertiseP3, ExpertiseP4, ExpertiseP5, WhyChoose1, WhyChoose2, WhyChoose3, WhyChoose4, WhyChoose5, WhyChoose6, ServiceIcon1, ServiceIcon2, ServiceIcon3, ServiceIcon4, NextArrow, UiUxIcon1, UiUxIcon2, UiUxIcon3, UiUxIcon4, SocialMedia1, SocialMedia2, SocialMedia3, SocialMedia4, WebEle1, WebEle2, SocialEle1, SocialEle2, UiEle1, TeamMember1, TeamMember2, TeamMember3, TeamMember4, TeamMember5, TeamMember6, TeamMember7, TeamMember8, TeamMember9, TeamMember10, TeamMember11, TeamMember12, TeamMember13, TeamMember14, NextArrow2, AppStore, PlayStore, WebLink, ReactIcon, FigmaIcon, PortBeepLogo, PortBeepImg, Client1, Client3, QuotationMark, Website4, Website1, Website3, Website5, Website6, Website7, Website8, Website9, Website10, Website11, App1, App2, App3, App4, Custom1, Custom2, Branding1, Branding2, Branding3, Branding4, Branding5, Branding6, Client6, Client7, Client8, Client9, Client10, Client11, Client12, Client13, Client14, Client15, ServiceIcon5, ServiceIcon6, ServiceIcon7, ServiceIcon8, Website12, Website13, Website14, Website2, AabIcon, NumuIcon, Star1Icon, Website1Pop, PortLogo2, Website2Pop, PortLogo3, Website3Pop, PortLogo4, Website4Pop, PortLogo5, Website5Pop, PortLogo6, Website6Pop, PortLogo7, Website7Pop, Website8Pop, PortLogo8, PortLogo9, Website9Pop, PortLogo10, Website10Pop, PortLogo11, Website11Pop, Website12Pop, PortLogo12, PortLogo13, Website13Pop, PortLogo14, Website14Pop, App2Pop, App3Pop, App4Pop, Custom1Pop, PortLogoC2, Custom2Pop, Branding1Pop, Branding2Pop, Branding3Pop, Branding4Pop, Branding5Pop, Branding6Pop, PortLogoB3, Branding7Pop, PortLogoB7, Branding7, DevItem1, DevItem4, DevItem5, DevItem6, DevItem3, DevItem2, DevItem31, DevItem32, DevItem33, DevItem34, DevItem35, DevItem36, DevItem37, DevItem38, DevItem39, DevItem40, DevItem41, DevItem42, DevItem43, DevItem44, DevItem46, DevItem47, DevItem48, DevItem49, DevItem75, DevItem74, DevItem73, DevItem72, DevItem71 } from "../Styled/AllImages"
import { ButtonWithIcon } from "../Styled/CustomElements"
import MobileDevelopments from "../sections/services/MobileDevelopments"
import WebDevelopments from "../sections/services/WebDevelopments"
import UiUxDesign from "../sections/services/UiUxDesign"
import SocialMedia from "../sections/services/SocialMedia"
import BeepTechListing from "../sections/portfolio/BeepTechList"
import VisualIdentity from "../sections/services/VisualIdentity"
import CustomSoftware from "../sections/services/CustomSoftware"
import DedicatedResource from "../sections/services/DedicatedResource"
import PrMoreDetail from "../sections/portfolio/ProjectMoreDetail"
import DaimounListing from "../sections/portfolio/DaimounList"
import PrMoreDetail7 from "../sections/portfolio/ProjectMoreDetail7"
import MaintenanceSupport from "../sections/services/MaintenanceSupport"



export const NavDataConfig = [
    // {
    //     to: 'home',
    //     label: 'Home',
    // },
    {
        to: 'about',
        label: 'About',
    },
    {
        to: 'services',
        label: 'Services',
    },
    {
        to: 'portfolio',
        label: 'Portfolio',
    },
    /* {
        to: 'team',
        label: 'Team',
    }, */
    {
        to: 'testimonials',
        label: 'Testimonials',
    },
    {
        to: 'clients',
        label: 'Clients',
    },
    /* {
        to: 'faqs',
        label: 'FAQs',
    },
    {
        target: '_blank',
        to: 'https://fowl.sa/blog/',
        label: 'Blog',
    }, */
    /* {
        to: 'contact',
        label: 'Contact',
    }, */
    
]
export const bannerItems = [
    {
        mainTxt: 'We_Deliver_App',
        mainTxtB: 'planed_designed',
        mainBannerHeading: 'BUILT_to_WIN',
        secondaryTxt: "We_bring_everything",
        img: <LazyImage src={Banner1} />,
    },
    

]

export const expertiseItems = [
    {
        img: <img src={ExpertiseP1} alt="ExpertiseP1" />,
        expertiseTxt: "Swift",
    },
    {
        img: <img src={ExpertiseP2} alt="ExpertiseP2" />,
        expertiseTxt: "Kotlin",
    },
    {
        img: <img src={ExpertiseP3} alt="ExpertiseP3" />,
        expertiseTxt: "React",
    },
    {
        img: <img src={ExpertiseP4} alt="ExpertiseP4" />,
        expertiseTxt: "Flutter",
    },
    {
        img: <img src={ExpertiseP5} alt="ExpertiseP5" />,
        expertiseTxt: "Laravel",
    },

]
export const WhyChooseItems = [
    {
        img: <img src={WhyChoose1} alt="WhyChoose1" />,
        expertiseTxt: "Award_winning_team",
    },
    {
        img: <img src={WhyChoose2} alt="WhyChoose2" />,
        expertiseTxt: "Decade_Long_Experience",
    },
    {
        img: <img src={WhyChoose3} alt="WhyChoose3" />,
        expertiseTxt: "Diverse_Tech_Stack",
    },
    {
        img: <img src={WhyChoose4} alt="WhyChoose4" />,
        expertiseTxt: "Bug_Free_Product",
    },
    {
        img: <img src={WhyChoose5} alt="WhyChoose5" />,
        expertiseTxt: "24_7_Support",
    },
    {
        img: <img src={WhyChoose6} alt="WhyChoose6" />,
        expertiseTxt: "Trusted_By_Millions",
    },
]


export const servicesItems = [
    {
        id: 0, // Unique identifier for the modal
        img: <img className='service-img' src={ServiceIcon1} alt="Service Image" />,
        serHeading: 'Mobile_App_Development',
        serTxt: 'DevTxt1',
        render: (openModal) => ( // Pass a function to open the modal
            <ButtonWithIcon 
            portClass='btnPryMedium'
                label='View_Details' 
                icon={NextArrow} 
                onClick={() => openModal(0)} // Pass the modal identifier to the openModal function
            />),
    },
    {
        id: 1, // Unique identifier for the modal
        img: <img className='service-img' src={ServiceIcon2} alt="Service Image" />,
        serHeading: 'Web_Development',
        serTxt: 'DevTxt2',
        render: (openModal) => ( // Pass a function to open the modal
            <ButtonWithIcon 
            portClass='btnPryMedium'
                label='View_Details' 
                icon={NextArrow} 
                onClick={() => openModal(1)} // Pass the modal identifier to the openModal function
            />),
    },
    {
        id: 2, // Unique identifier for the modal
        img: <img className='service-img' src={ServiceIcon3} alt="Service Image" />,
        serHeading: 'UI_UX_Design',
        serTxt: 'DevTxt3',
        render: (openModal) => ( // Pass a function to open the modal
            <ButtonWithIcon 
            portClass='btnPryMedium'
                label='View_Details'
                icon={NextArrow} 
                onClick={() => openModal(2)} // Pass the modal identifier to the openModal function
            />),
    },
    {
        id: 3, // Unique identifier for the modal
        img: <img className='service-img' src={ServiceIcon4} alt="Service Image" />,
        serHeading: 'Social_Media_Handling',
        serTxt: 'DevTxt4',
        render: (openModal) => ( // Pass a function to open the modal
            <ButtonWithIcon 
            portClass='btnPryMedium'
                label='View_Details' 
                icon={NextArrow} 
                onClick={() => openModal(3)} // Pass the modal identifier to the openModal function
            />),
    },
    {
        id: 4, // Unique identifier for the modal
        img: <img className='service-img' src={ServiceIcon5} alt="Service Image" />,
        serHeading: 'BrandingH',
        serTxt: 'DevTxt5',
        render: (openModal) => ( // Pass a function to open the modal
            <ButtonWithIcon 
            portClass='btnPryMedium'
                label='View_Details' 
                icon={NextArrow} 
                onClick={() => openModal(4)} // Pass the modal identifier to the openModal function
            />),
    },
    {
        id: 5, // Unique identifier for the modal
        img: <img className='service-img' src={ServiceIcon6} alt="Service Image" />,
        serHeading: 'Custom_Software_Development',
        serTxt: 'DevTxt6',
        render: (openModal) => ( // Pass a function to open the modal
            <ButtonWithIcon 
            portClass='btnPryMedium'
                label='View_Details' 
                icon={NextArrow} 
                onClick={() => openModal(5)} // Pass the modal identifier to the openModal function
            />),
    },
    {
        id: 6, // Unique identifier for the modal
        img: <img className='service-img' src={ServiceIcon7} alt="Service Image" />,
        serHeading: 'Dedicated_Resource_Outsourcing',
        serTxt: 'DevTxt7',
        render: (openModal) => ( // Pass a function to open the modal
            <ButtonWithIcon 
            portClass='btnPryMedium'
                label='View_Details' 
                icon={NextArrow} 
                onClick={() => openModal(6)} // Pass the modal identifier to the openModal function
            />),
    },
    {
        id: 7, // Unique identifier for the modal
        img: <img className='service-img' src={ServiceIcon8} alt="Service Image" />,
        serHeading: 'Maintenance_Technical_Support',
        serTxt: 'DevTxt8',
        render: (openModal) => ( // Pass a function to open the modal
            <ButtonWithIcon 
            portClass='btnPryMedium'
                label='View_Details' 
                icon={NextArrow} 
                onClick={() => openModal(7)} // Pass the modal identifier to the openModal function
            />),
    },
    
]


export const servicesModal = [
    {
        id: 0,
        mainImg: <img className='servicesModImg servicesModImgMd' src={ServiceIcon1} alt="Service Image" />,
        // headLabelF: "Mobile_App_Development",
        headLabelS: "Mobile_App_Development",
        detPara1: "mobile_Text",
        detPara2: "mobile_Text2",
        desDev: <MobileDevelopments />,
    },
    {
        id: 1,
        mainImg: <img className='servicesModImg servicesModImgWd' src={ServiceIcon2} alt="Service Image" />,
        ele1: <img className='servicesEleImg servicesEleImg1' src={WebEle1} alt="Service Image" />,
        ele2: <img className='servicesEleImg servicesEleImg2' src={WebEle2} alt="Service Image" />,
        // headLabelF: "Web",
        headLabelS: "Web_Development",
        detPara1: "web_Text",
        detPara2: "web_Text2",
        desDev: <WebDevelopments />,

    },
    {
        id: 2,
        mainImg: <img className='servicesModImg servicesModImgUiUx' src={ServiceIcon3} alt="Service Image" />,
        ele1: <img className='servicesEleImg servicesEleImg3' src={UiEle1} alt="Service Image" />,
        ele2: <img className='servicesEleImg servicesEleImg4' src={SocialEle1} alt="Service Image" />,
        // headLabelF: "UI_UX",
        headLabelS: "UI_UX_Design",
        detPara1: "UI_UX_Text",
        detPara2: "UI_UX_Text2",
        desDev: <UiUxDesign />,

    },
    {
        id: 3,
        mainImg: <img className='servicesModImg servicesModImgSocial' src={ServiceIcon4} alt="Service Image" />,
        ele1: <img className='servicesEleImg servicesEleImg5' src={SocialEle1} alt="Service Image" />,
        ele2: <img className='servicesEleImg servicesEleImg6' src={SocialEle2} alt="Service Image" />,
        // headLabelF: "Social_Media",
        headLabelS: "Social_Media_Handling",
        detPara1: "Social_Text",
        detPara2: "Social_Text2",
        desDev: <SocialMedia />,

    },
    {
        id: 4,
        mainImg: <img className='servicesModImg servicesModImgSocial' src={ServiceIcon5} alt="Service Image" />,
        // ele1: <img className='servicesEleImg servicesEleImg5' src={SocialEle1} alt="Service Image" />,
        // ele2: <img className='servicesEleImg servicesEleImg6' src={SocialEle2} alt="Service Image" />,
        // headLabelF: "Social_Media",
        headLabelS: "BrandingH",
        detPara1: "Visual_Text",
        detPara2: "Social_Text2",
        desDev: <VisualIdentity />,

    },
    {
        id: 5,
        mainImg: <img className='servicesModImg servicesModImgSocial' src={ServiceIcon6} alt="Service Image" />,
        // ele1: <img className='servicesEleImg servicesEleImg5' src={SocialEle1} alt="Service Image" />,
        // ele2: <img className='servicesEleImg servicesEleImg6' src={SocialEle2} alt="Service Image" />,
        // headLabelF: "Social_Media",
        headLabelS: "Custom_Software_Development",
        detPara1: "Custom_Text",
        detPara2: "Social_Text2",
        desDev: <CustomSoftware />,
    },
    {
        id: 6,
        mainImg: <img className='servicesModImg servicesModImgSocial' src={ServiceIcon7} alt="Service Image" />,
        // ele1: <img className='servicesEleImg servicesEleImg5' src={SocialEle1} alt="Service Image" />,
        // ele2: <img className='servicesEleImg servicesEleImg6' src={SocialEle2} alt="Service Image" />,
        // headLabelF: "Social_Media",
        headLabelS: "Dedicated_Resource_Outsourcing",
        detPara1: "Dedicated_Resource_Text",
        detPara2: "Social_Text2",
        desDev: <DedicatedResource />,
    },
    {
        id: 7,
        mainImg: <img className='servicesModImg servicesModImgSocial' src={ServiceIcon8} alt="Service Image" />,
        // ele1: <img className='servicesEleImg servicesEleImg5' src={SocialEle1} alt="Service Image" />,
        // ele2: <img className='servicesEleImg servicesEleImg6' src={SocialEle2} alt="Service Image" />,
        // headLabelF: "Social_Media",
        headLabelS: "Maintenance_Technical_Support",
        detPara1: "Maintenance_Support_Text",
        detPara2: "Social_Text2",
        desDev: <MaintenanceSupport />,
    },
]

export const MobileDevItems = [
    {
        img: <img src={DevItem1} alt="Mobile Dev." />,
        devTxt: "Swift",
    },
    {
        img: <img src={DevItem2} alt="Mobile Dev." />,
        devTxt: "Kotlin",
    },
    {
        img: <img src={DevItem3} alt="Mobile Dev." />,
        devTxt: "React",
    },
    {
        img: <img src={DevItem4} alt="Mobile Dev." />,
        devTxt: "Flutter",
    },
    {
        img: <img src={DevItem5} alt="Mobile Dev." />,
        devTxt: "Objective_C",
    },
    {
        img: <img src={DevItem6} alt="Mobile Dev." />,
        devTxt: "Java",
    },
]

export const WebDevItems = [
    {
        img: <img src={DevItem31} alt="Web Dev." />,
        devTxt: "Bootstrap",
    },
    {
        img: <img src={DevItem32} alt="Web Dev." />,
        devTxt: "Next_JS",
    },
    {
        img: <img src={DevItem33} alt="Web Dev." />,
        devTxt: "Angular",
    },
    {
        img: <img src={DevItem34} alt="Web Dev." />,
        devTxt: "React",
    },
    {
        img: <img src={DevItem35} alt="Web Dev." />,
        devTxt: "Express_JS",
    },
    {
        img: <img src={DevItem36} alt="Web Dev." />,
        devTxt: "HTML5",
    },
    {
        img: <img src={DevItem37} alt="Web Dev." />,
        devTxt: "jQuery",
    },
    {
        img: <img src={DevItem38} alt="Web Dev." />,
        devTxt: "NET",
    },
    {
        img: <img src={DevItem39} alt="Web Dev." />,
        devTxt: "Java",
    },
    {
        img: <img src={DevItem40} alt="Web Dev." />,
        devTxt: "Java_Script",
    },
    {
        img: <img src={DevItem41} alt="Web Dev." />,
        devTxt: "Python",
    },
    {
        img: <img src={DevItem42} alt="Web Dev." />,
        devTxt: "Node_JS",
    },
    {
        img: <img src={DevItem43} alt="Web Dev." />,
        devTxt: "PHP",
    },
    {
        img: <img src={DevItem44} alt="Web Dev." />,
        devTxt: "Ruby_on_Rails",
    },
    {
        img: <img src={DevItem46} alt="Web Dev." />,
        devTxt: "Mongo_DB",
    },
    {
        img: <img src={DevItem47} alt="Web Dev." />,
        devTxt: "SQL",
    },
    {
        img: <img src={DevItem48} alt="Web Dev." />,
        devTxt: "Firebase",
    },
    {
        img: <img src={DevItem49} alt="Web Dev." />,
        devTxt: "MySQL",
    },
    
]
export const UiUxDesItems = [
    {
        img: <img src={DevItem71} alt="Ui/Ux Dev." />,
        devTxt: "Figma",
    },
    {
        img: <img src={DevItem72} alt="Ui/Ux Dev." />,
        devTxt: "Adobe_XD",
    },
    {
        img: <img src={DevItem73} alt="Ui/Ux Dev." />,
        devTxt: "Photoshop",
    },
    
]

export const SocialMediaItems = [
    {
        img: <img src={SocialMedia1} alt="Social Media" />,
        devTxt: "Facebook",
    },
    {
        img: <img src={SocialMedia2} alt="Social Media" />,
        devTxt: "Twitter",
    },
    {
        img: <img src={SocialMedia3} alt="Social Media" />,
        devTxt: "Instagram",
    },
    {
        img: <img src={SocialMedia4} alt="Social Media" />,
        devTxt: "Linked_In",
    },
]
export const VisualIdentityItems = [
    {
        img: <img src={DevItem71} alt="Ui/Ux Dev." />,
        devTxt: "Figma",
    },
    {
        img: <img src={DevItem72} alt="Ui/Ux Dev." />,
        devTxt: "Adobe_XD",
    },
    {
        img: <img src={DevItem73} alt="Ui/Ux Dev." />,
        devTxt: "Photoshop",
    },
    {
        img: <img src={DevItem74} alt="Ui/Ux Dev." />,
        devTxt: "Illustrator",
    },
    {
        img: <img src={DevItem75} alt="Ui/Ux Dev." />,
        devTxt: "CorelDraw",
    },
]
export const CustomSoftwareItems = [
    {
        img: <img src={DevItem1} alt="Mobile Dev." />,
        devTxt: "Swift",
    },
    {
        img: <img src={DevItem2} alt="Mobile Dev." />,
        devTxt: "Kotlin",
    },
    {
        img: <img src={DevItem3} alt="Mobile Dev." />,
        devTxt: "React",
    },
    {
        img: <img src={DevItem4} alt="Mobile Dev." />,
        devTxt: "Flutter",
    },
    {
        img: <img src={DevItem5} alt="Mobile Dev." />,
        devTxt: "Objective_C",
    },
    {
        img: <img src={DevItem6} alt="Mobile Dev." />,
        devTxt: "Java",
    },
    {
        img: <img src={DevItem31} alt="Web Dev." />,
        devTxt: "Bootstrap",
    },
    {
        img: <img src={DevItem32} alt="Web Dev." />,
        devTxt: "Next_JS",
    },
    {
        img: <img src={DevItem33} alt="Web Dev." />,
        devTxt: "Angular",
    },
    {
        img: <img src={DevItem34} alt="Web Dev." />,
        devTxt: "React",
    },
    {
        img: <img src={DevItem35} alt="Web Dev." />,
        devTxt: "Express_JS",
    },
    {
        img: <img src={DevItem36} alt="Web Dev." />,
        devTxt: "HTML5",
    },
    {
        img: <img src={DevItem37} alt="Web Dev." />,
        devTxt: "jQuery",
    },
    {
        img: <img src={DevItem38} alt="Web Dev." />,
        devTxt: "NET",
    },
    {
        img: <img src={DevItem39} alt="Web Dev." />,
        devTxt: "Java",
    },
    {
        img: <img src={DevItem40} alt="Web Dev." />,
        devTxt: "Java_Script",
    },
    {
        img: <img src={DevItem41} alt="Web Dev." />,
        devTxt: "Python",
    },
    {
        img: <img src={DevItem42} alt="Web Dev." />,
        devTxt: "Node_JS",
    },
    {
        img: <img src={DevItem43} alt="Web Dev." />,
        devTxt: "PHP",
    },
    {
        img: <img src={DevItem44} alt="Web Dev." />,
        devTxt: "Ruby_on_Rails",
    },
    {
        img: <img src={DevItem46} alt="Web Dev." />,
        devTxt: "Mongo_DB",
    },
    {
        img: <img src={DevItem47} alt="Web Dev." />,
        devTxt: "SQL",
    },
    {
        img: <img src={DevItem48} alt="Web Dev." />,
        devTxt: "Firebase",
    },
    {
        img: <img src={DevItem49} alt="Web Dev." />,
        devTxt: "MySQL",
    },
]
export const DedicatedResourceItems = [
    {
        img: <img src={DevItem1} alt="Mobile Dev." />,
        devTxt: "Swift",
    },
    {
        img: <img src={DevItem2} alt="Mobile Dev." />,
        devTxt: "Kotlin",
    },
    {
        img: <img src={DevItem3} alt="Mobile Dev." />,
        devTxt: "React",
    },
    {
        img: <img src={DevItem4} alt="Mobile Dev." />,
        devTxt: "Flutter",
    },
    {
        img: <img src={DevItem5} alt="Mobile Dev." />,
        devTxt: "Objective_C",
    },
    {
        img: <img src={DevItem6} alt="Mobile Dev." />,
        devTxt: "Java",
    },
    {
        img: <img src={DevItem31} alt="Web Dev." />,
        devTxt: "Bootstrap",
    },
    {
        img: <img src={DevItem32} alt="Web Dev." />,
        devTxt: "Next_JS",
    },
    {
        img: <img src={DevItem33} alt="Web Dev." />,
        devTxt: "Angular",
    },
    {
        img: <img src={DevItem34} alt="Web Dev." />,
        devTxt: "React",
    },
    {
        img: <img src={DevItem35} alt="Web Dev." />,
        devTxt: "Express_JS",
    },
    {
        img: <img src={DevItem36} alt="Web Dev." />,
        devTxt: "HTML5",
    },
    {
        img: <img src={DevItem37} alt="Web Dev." />,
        devTxt: "jQuery",
    },
    {
        img: <img src={DevItem38} alt="Web Dev." />,
        devTxt: "NET",
    },
    {
        img: <img src={DevItem39} alt="Web Dev." />,
        devTxt: "Java",
    },
    {
        img: <img src={DevItem40} alt="Web Dev." />,
        devTxt: "Java_Script",
    },
    {
        img: <img src={DevItem41} alt="Web Dev." />,
        devTxt: "Python",
    },
    {
        img: <img src={DevItem42} alt="Web Dev." />,
        devTxt: "Node_JS",
    },
    {
        img: <img src={DevItem43} alt="Web Dev." />,
        devTxt: "PHP",
    },
    {
        img: <img src={DevItem44} alt="Web Dev." />,
        devTxt: "Ruby_on_Rails",
    },
    
]
export const MaintenanceSupportItems = [
    {
        img: <img src={UiUxIcon1} alt="Ui/Ux Dev." />,
        devTxt: "Figma",
    },
    {
        img: <img src={UiUxIcon2} alt="Ui/Ux Dev." />,
        devTxt: "Adobe_XD",
    },
    {
        img: <img src={UiUxIcon3} alt="Ui/Ux Dev." />,
        devTxt: "Photoshop",
    },
    {
        img: <img src={UiUxIcon4} alt="Ui/Ux Dev." />,
        devTxt: "Illustrator",
    },
]



export const teamItems = [
    {
        img: <img className='team-img' src={TeamMember1} alt="Team" />,
        memberName: 'Cameron_Williamson',
        memberRole: 'Founder_Chief_Executive',
    },
    {
        img: <img className='team-img' src={TeamMember2} alt="Team" />,
        memberName: 'Cameron_Williamson',
        memberRole: 'Founder_Chief_Executive',
    },
    {
        img: <img className='team-img' src={TeamMember3} alt="Team" />,
        memberName: 'Cameron_Williamson',
        memberRole: 'Founder_Chief_Executive',
    },
    {
        img: <img className='team-img' src={TeamMember4} alt="Team" />,
        memberName: 'Cameron_Williamson',
        memberRole: 'Founder_Chief_Executive',
    },
    {
        img: <img className='team-img' src={TeamMember5} alt="Team" />,
        memberName: 'Cameron_Williamson',
        memberRole: 'Founder_Chief_Executive',
    },
    {
        img: <img className='team-img' src={TeamMember6} alt="Team" />,
        memberName: 'Cameron_Williamson',
        memberRole: 'Founder_Chief_Executive',
    },
    {
        img: <img className='team-img' src={TeamMember7} alt="Team" />,
        memberName: 'Cameron_Williamson',
        memberRole: 'Founder_Chief_Executive',
    },
    {
        img: <img className='team-img' src={TeamMember8} alt="Team" />,
        memberName: 'Cameron_Williamson',
        memberRole: 'Founder_Chief_Executive',
    },
    {
        img: <img className='team-img' src={TeamMember9} alt="Team" />,
        memberName: 'Cameron_Williamson',
        memberRole: 'Founder_Chief_Executive',
    },
    {
        img: <img className='team-img' src={TeamMember10} alt="Team" />,
        memberName: 'Cameron_Williamson',
        memberRole: 'Founder_Chief_Executive',
    },
    {
        img: <img className='team-img' src={TeamMember11} alt="Team" />,
        memberName: 'Cameron_Williamson',
        memberRole: 'Founder_Chief_Executive',
    },
    {
        img: <img className='team-img' src={TeamMember12} alt="Team" />,
        memberName: 'Cameron_Williamson',
        memberRole: 'Founder_Chief_Executive',
    },
    {
        img: <img className='team-img' src={TeamMember13} alt="Team" />,
        memberName: 'Cameron Williamson',
        memberRole: 'Founder_Chief_Executive',
    },
    {
        img: <img className='team-img' src={TeamMember14} alt="Team" />,
        memberName: 'Cameron Williamson',
        memberRole: 'Founder/Chief Executive Officer (CEO)',
    },
]


export const portfolioItems = [
    {
        id: 1,
        category: "Website",
        img: <img className='portfolio-img' src={Website1} alt="Portfolio" />,
        render: (openModals) => ( // Pass a function to open the modal
            <ButtonWithIcon 
                label='View_Details' 
                icon={NextArrow2} 
                portClass='portfolioBtn'
                onClick={() => openModals(1)} // Pass the modal identifier to the openModal function
            />),
    },
    {
        id: 2,
        category: "Website",
        img: <img className='portfolio-img' src={Website2} alt="Portfolio" />,
        render: (openModals) => ( // Pass a function to open the modal
            <ButtonWithIcon 
                label='View_Details' 
                icon={NextArrow2} 
                portClass='portfolioBtn'
                onClick={() => openModals(2)} // Pass the modal identifier to the openModal function
            />),
    },
    {
        id: 3,
        category: "Website",
        img: <img className='portfolio-img' src={Website3} alt="Portfolio" />,
        render: (openModals) => ( // Pass a function to open the modal
            <ButtonWithIcon 
                label='View_Details' 
                icon={NextArrow2} 
                portClass='portfolioBtn'
                onClick={() => openModals(3)} // Pass the modal identifier to the openModal function
            />),
    },
    {
        id: 4,
        category: "Website",
        img: <img className='portfolio-img' src={Website4} alt="Portfolio" />,
        render: (openModals) => ( // Pass a function to open the modal
            <ButtonWithIcon 
                label='View_Details' 
                icon={NextArrow2} 
                portClass='portfolioBtn'
                onClick={() => openModals(4)} // Pass the modal identifier to the openModal function
            />),
    },
    {
        id: 5,
        category: "Website",
        img: <img className='portfolio-img' src={Website5} alt="Portfolio" />,
        render: (openModals) => ( // Pass a function to open the modal
            <ButtonWithIcon 
                label='View_Details' 
                icon={NextArrow2} 
                portClass='portfolioBtn'
                onClick={() => openModals(5)} // Pass the modal identifier to the openModal function
            />),
    },
    {
        id: 6,
        category: "Website",
        img: <img className='portfolio-img' src={Website6} alt="Portfolio" />,
        render: (openModals) => ( // Pass a function to open the modal
            <ButtonWithIcon 
                label='View_Details' 
                icon={NextArrow2} 
                portClass='portfolioBtn'
                onClick={() => openModals(6)} // Pass the modal identifier to the openModal function
            />),
    },
    {
        id: 7,
        category: "Website",
        img: <img className='portfolio-img' src={Website7} alt="Portfolio" />,
        render: (openModals) => ( // Pass a function to open the modal
            <ButtonWithIcon 
                label='View_Details' 
                icon={NextArrow2} 
                portClass='portfolioBtn'
                onClick={() => openModals(7)} // Pass the modal identifier to the openModal function
            />),
    },
    {
        id: 8,
        category: "Website",
        img: <img className='portfolio-img' src={Website8} alt="Portfolio" />,
        render: (openModals) => ( // Pass a function to open the modal
            <ButtonWithIcon 
                label='View_Details' 
                icon={NextArrow2} 
                portClass='portfolioBtn'
                onClick={() => openModals(8)} // Pass the modal identifier to the openModal function
            />),
    },
    {
        id: 9,
        category: "Website",
        img: <img className='portfolio-img' src={Website9} alt="Portfolio" />,
        render: (openModals) => ( // Pass a function to open the modal
            <ButtonWithIcon 
                label='View_Details' 
                icon={NextArrow2} 
                portClass='portfolioBtn'
                onClick={() => openModals(9)} // Pass the modal identifier to the openModal function
            />),
    },
    {
        id: 10,
        category: "Website",
        img: <img className='portfolio-img' src={Website10} alt="Portfolio" />,
        render: (openModals) => ( // Pass a function to open the modal
            <ButtonWithIcon 
                label='View_Details' 
                icon={NextArrow2} 
                portClass='portfolioBtn'
                onClick={() => openModals(10)} // Pass the modal identifier to the openModal function
            />),
    },
    {
        id: 11,
        category: "Website",
        img: <img className='portfolio-img' src={Website11} alt="Portfolio" />,
        render: (openModals) => ( // Pass a function to open the modal
            <ButtonWithIcon 
                label='View_Details' 
                icon={NextArrow2} 
                portClass='portfolioBtn'
                onClick={() => openModals(11)} // Pass the modal identifier to the openModal function
            />),
    },
    {
        id: 12,
        category: "Website",
        img: <img className='portfolio-img' src={Website12} alt="Portfolio" />,
        render: (openModals) => ( // Pass a function to open the modal
            <ButtonWithIcon 
                label='View_Details' 
                icon={NextArrow2} 
                portClass='portfolioBtn'
                onClick={() => openModals(12)} // Pass the modal identifier to the openModal function
            />),
    },
    {
        id: 13,
        category: "Website",
        img: <img className='portfolio-img' src={Website13} alt="Portfolio" />,
        render: (openModals) => ( // Pass a function to open the modal
            <ButtonWithIcon 
                label='View_Details' 
                icon={NextArrow2} 
                portClass='portfolioBtn'
                onClick={() => openModals(13)} // Pass the modal identifier to the openModal function
            />),
    },
    {
        id: 14,
        category: "Website",
        img: <img className='portfolio-img' src={Website14} alt="Portfolio" />,
        render: (openModals) => ( // Pass a function to open the modal
            <ButtonWithIcon 
                label='View_Details' 
                icon={NextArrow2} 
                portClass='portfolioBtn'
                onClick={() => openModals(14)} // Pass the modal identifier to the openModal function
            />),
    },
    
    {
        id: 21,
        category: "Mobile_Apps",
        img: <img className='portfolio-img' src={App1} alt="Portfolio" />,
        render: (openModals) => ( // Pass a function to open the modal
            <ButtonWithIcon 
                label='View_Details' 
                icon={NextArrow2} 
                portClass='portfolioBtn'
                onClick={() => openModals(21)} // Pass the modal identifier to the openModal function
            />),
    },
    {
        id: 22,
        category: "Mobile_Apps",
        img: <img className='portfolio-img' src={App2} alt="Portfolio" />,
        render: (openModals) => ( // Pass a function to open the modal
            <ButtonWithIcon 
                label='View_Details' 
                icon={NextArrow2} 
                portClass='portfolioBtn'
                onClick={() => openModals(22)} // Pass the modal identifier to the openModal function
            />),
    },
    {
        id: 23,
        category: "Mobile_Apps",
        img: <img className='portfolio-img' src={App3} alt="Portfolio" />,
        render: (openModals) => ( // Pass a function to open the modal
            <ButtonWithIcon 
                label='View_Details' 
                icon={NextArrow2} 
                portClass='portfolioBtn'
                onClick={() => openModals(23)} // Pass the modal identifier to the openModal function
            />),
    },
    {
        id: 24,
        category: "Mobile_Apps",
        img: <img className='portfolio-img' src={App4} alt="Portfolio" />,
        render: (openModals) => ( // Pass a function to open the modal
            <ButtonWithIcon 
                label='View_Details' 
                icon={NextArrow2} 
                portClass='portfolioBtn'
                onClick={() => openModals(24)} // Pass the modal identifier to the openModal function
            />),
    },
    {
        id: 41,
        category: "Custom_Software",
        img: <img className='portfolio-img' src={Custom1} alt="Portfolio" />,
        render: (openModals) => ( // Pass a function to open the modal
            <ButtonWithIcon 
                label='View_Details' 
                icon={NextArrow2} 
                portClass='portfolioBtn'
                onClick={() => openModals(41)} // Pass the modal identifier to the openModal function
            />),
    },
    {
        id: 42,
        category: "Custom_Software",
        img: <img className='portfolio-img' src={Custom2} alt="Portfolio" />,
        render: (openModals) => ( // Pass a function to open the modal
            <ButtonWithIcon 
                label='View_Details' 
                icon={NextArrow2} 
                portClass='portfolioBtn'
                onClick={() => openModals(42)} // Pass the modal identifier to the openModal function
            />),
    },
    {
        id: 61,
        category: "Branding",
        img: <img className='portfolio-img' src={Branding1} alt="Portfolio" />,
        render: (openModals) => ( // Pass a function to open the modal
            <ButtonWithIcon 
                label='View_Details' 
                icon={NextArrow2} 
                portClass='portfolioBtn'
                onClick={() => openModals(61)} // Pass the modal identifier to the openModal function
            />),
    },
    {
        id: 62,
        category: "Branding",
        img: <img className='portfolio-img' src={Branding2} alt="Portfolio" />,
        render: (openModals) => ( // Pass a function to open the modal
            <ButtonWithIcon 
                label='View_Details' 
                icon={NextArrow2} 
                portClass='portfolioBtn'
                onClick={() => openModals(62)} // Pass the modal identifier to the openModal function
            />),
    },
    {
        id: 63,
        category: "Branding",
        img: <img className='portfolio-img' src={Branding3} alt="Portfolio" />,
        render: (openModals) => ( // Pass a function to open the modal
            <ButtonWithIcon 
                label='View_Details' 
                icon={NextArrow2} 
                portClass='portfolioBtn'
                onClick={() => openModals(63)} // Pass the modal identifier to the openModal function
            />),
    },
    {
        id: 64,
        category: "Branding",
        img: <img className='portfolio-img' src={Branding4} alt="Portfolio" />,
        render: (openModals) => ( // Pass a function to open the modal
            <ButtonWithIcon 
                label='View_Details' 
                icon={NextArrow2} 
                portClass='portfolioBtn'
                onClick={() => openModals(64)} // Pass the modal identifier to the openModal function
            />),
    },
    {
        id: 65,
        category: "Branding",
        img: <img className='portfolio-img' src={Branding5} alt="Portfolio" />,
        render: (openModals) => ( // Pass a function to open the modal
            <ButtonWithIcon 
                label='View_Details' 
                icon={NextArrow2} 
                portClass='portfolioBtn'
                onClick={() => openModals(65)} // Pass the modal identifier to the openModal function
            />),
    },
    {
        id: 66,
        category: "Branding",
        img: <img className='portfolio-img' src={Branding6} alt="Portfolio" />,
        render: (openModals) => ( // Pass a function to open the modal
            <ButtonWithIcon 
                label='View_Details' 
                icon={NextArrow2} 
                portClass='portfolioBtn'
                onClick={() => openModals(66)} // Pass the modal identifier to the openModal function
            />),
    },
    {
        id: 67,
        category: "Branding",
        img: <img className='portfolio-img' src={Branding7} alt="Portfolio" />,
        render: (openModals) => ( // Pass a function to open the modal
            <ButtonWithIcon 
                label='View_Details' 
                icon={NextArrow2} 
                portClass='portfolioBtn'
                onClick={() => openModals(67)} // Pass the modal identifier to the openModal function
            />),
    },

    
]


export const portfolioModal = [
    {
        id: 1,
        logoImg: <PortBeepLogo className='portfolioModLogo portfolioModLogoBeep' />,
        mainImg: <img className='portfolioModImg portfolioModImgBeep' src={Website1Pop} alt="Portfolio" />,
        projectName: "Beep_Alla_Beep",
        projectDesc: "projectModText",
        projectSolution: "solutionText",
        projectMoreDetail: <PrMoreDetail />,
        // projectList: <BeepListing />,
        projectVisit: [
            {
                beepVisit: <img className='portfolioVisitImg' src={AppStore} alt="App Store" />,
                beepLink: "https://apps.apple.com/us/app/beep-i-%D8%A8%D9%8A%D8%A8/id6446269432",
            },
            
            {
                beepVisit: <img className='portfolioVisitImg' src={PlayStore} alt="Play Store" />,
                beepLink: "https://play.google.com/store/apps/details?id=com.app4orce.beepallabeep&pli=1",
        
            },
            {
                beepVisit: <img className='portfolioVisitImg' src={WebLink} alt="Website" />,
                beepLink: "https://beepallabeep.com/",
        
            },
        ],
        // projectTech: <BeepTechListing />,

    },
    {
        id: 2,
        logoImg: <PortLogo2 className='portfolioModLogo portfolioModLogoDaimoun' />,
        mainImg: <img className='portfolioModImg portfolioModImgDaimoun' src={Website2Pop} alt="Portfolio" />,
        projectName: "Daimoun",
        projectDesc: "projectModText2",
        projectSolution: "solutionText",
        projectList: <DaimounListing />,
        projectVisit: [
            {
                beepVisit: <img className='portfolioVisitImg' src={WebLink} alt="Portfolio" />,
                beepLink: "https://daimoun.com/",
        
            },
        ],
        projectTech: <BeepTechListing />,

    },
    {
        id: 3,
        logoImg: <PortLogo3 className='portfolioModLogo portfolioModLogoFustanie' />,
        mainImg: <img className='portfolioModImg portfolioModImgFustanie' src={Website3Pop} alt="Portfolio" />,
        projectName: "Fustanie",
        projectDesc: "projectModText3",
        projectVisit: [
            {
                beepVisit: <img className='portfolioVisitImg' src={AppStore} alt="AppStore" />,
                beepLink: "https://apps.apple.com/us/app/%D9%81%D8%B3%D8%AA%D8%A7%D9%86%D9%8A/id1659827110",
            },
            
            {
                beepVisit: <img className='portfolioVisitImg' src={PlayStore} alt="PortfPlayStoreolio" />,
                beepLink: "https://play.google.com/store/apps/details?id=com.myapp.fustani",
        
            },
            {
                beepVisit: <img className='portfolioVisitImg' src={WebLink} alt="WebLink" />,
                beepLink: "https://fustanie.com/",
        
            },
        ],

    },
    {
        id: 4,
        logoImg: <PortLogo4 className='portfolioModLogo portfolioModLogoJay' />,
        mainImg: <img className='portfolioModImg portfolioModImgJay' src={Website4Pop} alt="Portfolio" />,
        projectName: "Jay",
        projectDesc: "projectModText4",
        projectVisit: [
            {
                beepVisit: <img className='portfolioVisitImg' src={AppStore} alt="AppStore" />,
                beepLink: "https://apps.apple.com/sa/app/%D8%AC%D8%A7%D9%8A-%D8%A8%D8%A7%D9%84%D8%B7%D8%B1%D9%8A%D9%82/id6479860675",
            },
            
            {
                beepVisit: <img className='portfolioVisitImg' src={PlayStore} alt="PlayStore" />,
                beepLink: "https://play.google.com/store/apps/details?id=com.app4orce.jaaiuser",
        
            },
            {
                beepVisit: <img className='portfolioVisitImg' src={WebLink} alt="WebLink" />,
                beepLink: "http://jay.sa/",
        
            },
        ],
        projectTech: <BeepTechListing />,

    },
    {
        id: 5,
        logoImg: <PortLogo5 className='portfolioModLogo portfolioModLogoNumu' />,
        mainImg: <img className='portfolioModImg portfolioModImgNumu' src={Website5Pop} alt="Portfolio" />,
        projectName: "Numu",
        projectDesc: "projectModText5",
        projectSolution: "solutionText",
        projectVisit: [
            {
                beepVisit: <img className='portfolioVisitImg' src={WebLink} alt="WebLink" />,
                beepLink: "http://numu.sa/",
        
            },
        ],
        projectTech: <BeepTechListing />,

    },
    {
        id: 6,
        logoImg: <PortLogo6 className='portfolioModLogo portfolioModLogoSahab' />,
        mainImg: <img className='portfolioModImg portfolioModImgSahab' src={Website6Pop} alt="Portfolio" />,
        projectName: "SahabAlam",
        projectDesc: "projectModText6",
        projectVisit: [
            {
                beepVisit: <img className='portfolioVisitImg' src={WebLink} alt="WebLink" />,
                beepLink: "https://sahabalalam.sa/",
        
            },
        ],
        projectTech: <BeepTechListing />,

    },
    {
        id: 7,
        logoImg: <PortLogo7 className='portfolioModLogo portfolioModLogoBarrak' />,
        mainImg: <img className='portfolioModImg portfolioModImgBarrak' src={Website7Pop}  alt="Portfolio" />,
        projectName: "AlBrrak",
        projectDesc: "projectModText7",
        projectMoreDetail: <PrMoreDetail7 />,
        projectVisit: [
            {
                beepVisit: <img className='portfolioVisitImg' src={WebLink} alt="WebLink" />,
                beepLink: "https://albarraktrd.sa/",
        
            },
        ],
        projectTech: <BeepTechListing />,

    },
    {
        id: 8,
        logoImg: <PortLogo8 className='portfolioModLogo portfolioModLogoAbraaj' />,
        mainImg: <img className='portfolioModImg portfolioModImgAbraaj' src={Website8Pop} alt="Portfolio" />,
        projectName: "Abraaj",
        projectDesc: "projectModText8",
        projectVisit: [
            {
                beepVisit: <img className='portfolioVisitImg' src={WebLink} alt="WebLink" />,
                beepLink: "https://abraj.sa/",
        
            },
        ],
        projectTech: <BeepTechListing />,

    },
    {
        id: 9,
        logoImg: <PortLogo9 className='portfolioModLogo portfolioModLogoAHouse' />,
        mainImg: <img className='portfolioModImg portfolioModImgAHouse' src={Website9Pop} alt="Portfolio" />,
        projectName: "AdvanceHousing",
        projectDesc: "projectModText9",
        projectVisit: [
            {
                beepVisit: <img className='portfolioVisitImg' src={WebLink} alt="WebLink" />,
                beepLink: "https://ahouse.sa/",
        
            },
        ],
        projectTech: <BeepTechListing />,

    },
    {
        id: 10,
        logoImg: <PortLogo10 className='portfolioModLogo portfolioModLogoDeyam' />,
        mainImg: <img className='portfolioModImg portfolioModImgDeyam' src={Website10Pop} alt="Portfolio" />,
        projectName: "Deyam",
        projectDesc: "projectModText10",
        projectVisit: [
            {
                beepVisit: <img className='portfolioVisitImg' src={WebLink} alt="WebLink" />,
                beepLink: "https://deyam.app4orce.com/",
        
            },
        ],
        projectTech: <BeepTechListing />,

    },
    {
        id: 11,
        logoImg: <PortLogo11 className='portfolioModLogo portfolioModLogoStay' />,
        mainImg: <img className='portfolioModImg portfolioModImgStay' src={Website11Pop} alt="Portfolio" />,
        projectName: "Stay",
        projectDesc: "projectModText11",
        projectVisit: [
            {
                beepVisit: <img className='portfolioVisitImg' src={AppStore} alt="AppStore" />,
                beepLink: "https://apps.apple.com/us/app/stay-%D8%B3%D8%AA%D8%A7%D9%8A/id6502040616",
            },
            
            {
                beepVisit: <img className='portfolioVisitImg' src={PlayStore} alt="PlayStore" />,
                beepLink: "https://play.google.com/store/apps/details?id=com.app4orce.stayguest",
        
            },
            {
                beepVisit: <img className='portfolioVisitImg' src={WebLink} alt="WebLink" />,
                beepLink: "https://stay.com.sa/",
        
            },
        ],
    },
    {
        id: 12,
        logoImg: <PortLogo12 className='portfolioModLogo portfolioModLogoNabd' />,
        mainImg: <img className='portfolioModImg portfolioModImgNabd' src={Website12Pop} alt="Portfolio" />,
        projectName: "Nabd",
        projectDesc: "projectModText12",
        projectVisit: [
            {
                beepVisit: <img className='portfolioVisitImg' src={WebLink} alt="WebLink" />,
                beepLink: "http://nabd.com.sa/",
        
            },
        ],
    },
    {
        id: 13,
        logoImg: <PortLogo13 className='portfolioModLogo portfolioModLogoQuantum' />,
        mainImg: <img className='portfolioModImg portfolioModImgQuantum' src={Website13Pop} alt="Portfolio" />,
        projectName: "Quantum",
        projectDesc: "projectModText13",
        projectVisit: [
            {
                beepVisit: <img className='portfolioVisitImg' src={WebLink} alt="WebLink" />,
                beepLink: "https://quantumdigital-rtn.com/",
        
            },
        ],
    },
    {
        id: 14,
        logoImg: <PortLogo14 className='portfolioModLogo portfolioModLogoAAB' />,
        mainImg: <img className='portfolioModImg portfolioModImgAAB' src={Website14Pop} alt="Portfolio" />,
        projectName: "AAB",
        projectDesc: "projectModText14",
        projectVisit: [
            {
                beepVisit: <img className='portfolioVisitImg' src={WebLink} alt="WebLink" />,
                beepLink: "https://topmodern.sa/",
        
            },
        ],
    },
    
    {
        id: 21,
        logoImg: <PortBeepLogo className='portfolioModLogo portfolioModLogoBeep' />,
        mainImg: <img className='portfolioModImg portfolioModImgBeep rightColImgBeepApp' src={PortBeepImg} alt="Portfolio" />,
        projectName: "Beep_Alla_Beep",
        projectDesc: "projectModText",
        projectSolution: "solutionText",
        projectMoreDetail: <PrMoreDetail />,
        projectVisit: [
            {
                beepVisit: <img className='portfolioVisitImg' src={AppStore} alt="AppStore" />,
                beepLink: "https://apps.apple.com/us/app/beep-i-%D8%A8%D9%8A%D8%A8/id6446269432",
            },
            
            {
                beepVisit: <img className='portfolioVisitImg' src={PlayStore} alt="PlayStore" />,
                beepLink: "https://play.google.com/store/apps/details?id=com.app4orce.beepallabeep&pli=1",
        
            },
            {
                beepVisit: <img className='portfolioVisitImg' src={WebLink} alt="WebLink" />,
                beepLink: "https://beepallabeep.com/",
        
            },
        ],

    },
    {
        id: 22,
        logoImg: <PortLogo11 className='portfolioModLogo portfolioModLogoStayApp' />,
        mainImg: <img className='portfolioModImg portfolioModImgStayApp' src={App2Pop} alt="Portfolio" />,
        projectName: "Stay",
        projectDesc: "projectModText11",
        projectVisit: [
            {
                beepVisit: <img className='portfolioVisitImg' src={AppStore} alt="AppStore" />,
                beepLink: "https://apps.apple.com/us/app/stay-%D8%B3%D8%AA%D8%A7%D9%8A/id6502040616",
            },
            
            {
                beepVisit: <img className='portfolioVisitImg' src={PlayStore} alt="PlayStore" />,
                beepLink: "https://play.google.com/store/apps/details?id=com.app4orce.stayguest",
        
            },
            {
                beepVisit: <img className='portfolioVisitImg' src={WebLink} alt="WebLink" />,
                beepLink: "https://stay.com.sa/",
        
            },
        ],

    },
    {
        id: 23,
        logoImg: <PortLogo4 className='portfolioModLogo portfolioModLogoJayApp' />,
        mainImg: <img className='portfolioModImg portfolioModImgJayApp' src={App3Pop} alt="Portfolio" />,
        projectName: "Jay",
        projectDesc: "projectModText4",
        projectVisit: [
            {
                beepVisit: <img className='portfolioVisitImg' src={AppStore} alt="AppStore" />,
                beepLink: "https://apps.apple.com/sa/app/%D8%AC%D8%A7%D9%8A-%D8%A8%D8%A7%D9%84%D8%B7%D8%B1%D9%8A%D9%82/id6479860675",
            },
            
            {
                beepVisit: <img className='portfolioVisitImg' src={PlayStore} alt="PlayStore" />,
                beepLink: "https://play.google.com/store/apps/details?id=com.app4orce.jaaiuser",
        
            },
            {
                beepVisit: <img className='portfolioVisitImg' src={WebLink} alt="WebLink" />,
                beepLink: "http://jay.sa/",
        
            },
        ],
    },
    {
        id: 24,
        logoImg: <PortLogo3 className='portfolioModLogo portfolioModLogoFustanieApp' />,
        mainImg: <img className='portfolioModImg portfolioModImgFustanieApp' src={App4Pop} alt="Portfolio" />,
        projectName: "Fustanie",
        projectDesc: "projectModText3",
        projectVisit: [
            {
                beepVisit: <img className='portfolioVisitImg' src={AppStore} alt="AppStore" />,
                beepLink: "https://apps.apple.com/us/app/%D9%81%D8%B3%D8%AA%D8%A7%D9%86%D9%8A/id1659827110",
            },
            
            {
                beepVisit: <img className='portfolioVisitImg' src={PlayStore} alt="PlayStore" />,
                beepLink: "https://play.google.com/store/apps/details?id=com.myapp.fustani",
        
            },
            {
                beepVisit: <img className='portfolioVisitImg' src={WebLink} alt="WebLink" />,
                beepLink: "https://fustanie.com/",
        
            },
        ],
    },

    {
        id: 41,
        logoImg: <PortBeepLogo className='portfolioModLogo portfolioModLogoBeepCs' />,
        mainImg: <img className='portfolioModImg portfolioModImgBeepCs' src={Custom1Pop} alt="Portfolio" />,
        projectName: "Beep_Alla_Beep",
        projectDesc: "projectModText",
        projectSolution: "solutionText",
        projectMoreDetail: <PrMoreDetail />,
        projectVisit: [
            {
                beepVisit: <img className='portfolioVisitImg' src={AppStore} alt="AppStore" />,
                beepLink: "https://apps.apple.com/us/app/beep-i-%D8%A8%D9%8A%D8%A8/id6446269432",
            },
            
            {
                beepVisit: <img className='portfolioVisitImg' src={PlayStore} alt="PlayStore" />,
                beepLink: "https://play.google.com/store/apps/details?id=com.app4orce.beepallabeep&pli=1",
        
            },
            {
                beepVisit: <img className='portfolioVisitImg' src={WebLink} alt="WebLink" />,
                beepLink: "https://beepallabeep.com/",
        
            },
        ],

    },
    {
        id: 42,
        logoImg: <PortLogoC2 className='portfolioModLogo portfolioModLogoPRM' />,
        mainImg: <img className='portfolioModImg portfolioModImgPRM' src={Custom2Pop} alt="Portfolio" />,
        projectName: "PRM",
        projectDesc: "projectModText42",
        projectVisit: [
            {
                beepVisit: <img className='portfolioVisitImg portfolioVisitImgBeep' src={WebLink} alt="WebLink" />,
                beepLink: "https://portal.prm.sa/",
        
            },
        ],

    },

    {
        id: 61,
        logoImg: <PortLogo8 className='portfolioModLogo portfolioModLogoAbraaj' />,
        mainImg: <img className='portfolioModImg portfolioModImgAbraaj' src={Branding1Pop} alt="Branding" />,
        projectName: "Abraaj",
        projectDesc: "projectModText8",
    },
    {
        id: 62,
        logoImg: <PortLogo7 className='portfolioModLogo portfolioModLogoBarrak' />,
        mainImg: <img className='portfolioModImg portfolioModImgBarrak' src={Branding2Pop} alt="Branding" />,
        projectName: "AlBrrak",
        projectDesc: "projectModText7",
    },
    {
        id: 63,
        logoImg: <PortLogoB3 className='portfolioModLogo portfolioModLogoMaq' />,
        mainImg: <img className='portfolioModImg portfolioModImgMaq' src={Branding3Pop} alt="Branding" />,
        projectName: "Maq",
        projectDesc: "projectModText63",
    },
    {
        id: 64,
        logoImg: <PortLogo8 className='portfolioModLogo portfolioModLogoAbraaj' />,
        mainImg: <img className='portfolioModImg portfolioModImgAbraaj' src={Branding4Pop} alt="Branding" />,
        projectName: "Abraaj",
        projectDesc: "projectModText8",
    },
    {
        id: 65,
        logoImg: <PortLogo7 className='portfolioModLogo portfolioModLogoBarrak' />,
        mainImg: <img className='portfolioModImg portfolioModImgBarrak' src={Branding5Pop} alt="Branding" />,
        projectName: "AlBrrak",
        projectDesc: "projectModText7",
    },
    {
        id: 66,
        logoImg: <PortLogoB3 className='portfolioModLogo portfolioModLogoMaq' />,
        mainImg: <img className='portfolioModImg portfolioModImgMaq' src={Branding6Pop} alt="Branding" />,
        projectName: "Maq",
        projectDesc: "projectModText63",
    },
    {
        id: 67,
        logoImg: <PortLogoB7 className='portfolioModLogo portfolioModLogoSafowa' />,
        mainImg: <img className='portfolioModImg portfolioModImgSafowa' src={Branding7Pop} alt="Branding" />,
        projectName: "Safowa",
        projectDesc: "projectModText67",
    },

]

export const BeepList = [
    {
        BeepListTxt: "ListingTxt1",
    },
    {
        BeepListTxt:  "ListingTxt2",
    },
    {
        BeepListTxt: "ListingTxt3",
    },
]
export const DaimounList = [
    {
        ListTxt: "ListingTxtDa1",
    },
    {
        ListTxt:  "ListingTxtDa2",
    },
    {
        ListTxt: "ListingTxtDa3",
    },
    {
        ListTxt: "ListingTxtDa4",
    },
]

export const BeepTechList = [
    {
        beepTechIcon: <img className='portfolioTechImg portfolioTechImgBeep' src={ReactIcon} alt="ReactIcon" />,
    },
    {
        beepTechIcon: <img className='portfolioTechImg portfolioTechImgBeep' src={FigmaIcon} alt="FigmaIcon" />,
    },
    
]


export const clientsItems = [
    {
        img: <img className='client-img' src={Client1} alt="Client" />,
    },
    // {
    //     img: <img className='client-img' src={Client2} alt="Client" />,
    // },
    {
        img: <img className='client-img' src={Client3} alt="Client" />,
    },
    /* {
        img: <img className='client-img' src={Client4} alt="Client" />,
    },
    {
        img: <img className='client-img' src={Client5} alt="Client" />,
    }, */
    {
        img: <img className='client-img' src={Client6} alt="Client" />,
    },
    {
        img: <img className='client-img' src={Client7} alt="Client" />,
    },
    {
        img: <img className='client-img' src={Client8} alt="Client" />,
    },
    {
        img: <img className='client-img' src={Client9} alt="Client" />,
    },
    {
        img: <img className='client-img' src={Client10} alt="Client" />,
    },
    {
        img: <img className='client-img' src={Client11} alt="Client" />,
    },
    {
        img: <img className='client-img' src={Client12} alt="Client" />,
    },
    {
        img: <img className='client-img' src={Client13} alt="Client" />,
    },
    {
        img: <img className='client-img' src={Client14} alt="Client" />,
    },
    {
        img: <img className='client-img' src={Client15} alt="Client" />,
    },
]


export const testimonialsItems = [
    {
        img: <img className='testimonial-img' src={AabIcon} alt="Testimonial" />,
        imgQuote: <QuotationMark />,
        tesHeading: 'client_name_1',
        tesDesig: 'Top_Modern',
        // tesDesigOl: 'CEO',
        tesTxt: 'Website_Content_1',
    },
    {
        img: <img className='testimonial-img' src={NumuIcon} alt="Testimonial" />,
        imgQuote: <QuotationMark />,
        tesHeading: 'client_name_2',
        tesDesig: 'Numu_Garden',
        tesDesigOl: 'CEO',
        tesTxt: 'Website_Content_2',
    },
    {
        img: <img className='testimonial-img' src={Star1Icon}  alt="Testimonial"/>,
        imgQuote: <QuotationMark />,
        tesHeading: 'client_name_3',
        tesDesig: 'Star1',
        tesDesigOl: 'CEO',
        tesTxt: 'Website_Content_3',
    },
    /* {
        img: <img className='testimonial-img' src={TesImg1} alt="Testimonial" />,
        imgQuote: <QuotationMark />,
        tesHeading: 'tesName',
        tesDesig: 'tesDut',
        tesTxt: 'tesText',
    }, */
    

]

export const agendaOption = [
    {
        label: "Mobile_Apps",
        value: "Mobile App",
    },
    {
        label: "Website",
        value: "Website",
    },
    {
        label: "UI_UX_Design",
        value: "UI/UX Design",
    },
    {
        label: "SEO",
        value: "SEO",
    },
    {
        label: "Digital_Marketing",
        value: "Digital Marketing",
    },
    {
        label: "Content_Writing",
        value: "Content Writing",
    },
    {
        label: "Custom_Software",
        value: "Custom Software",
    },
    {
        label: "Branding",
        value: "Branding",
    },
    
];


export const allFaqs = [
    {
        heading: 'faqH1',
        text: 'LoremIpsum',
        
    },
    {
        heading: 'faqH2',
        text: 'LoremIpsum',
        
    },
    {
        heading: 'faqH3',
        text: 'LoremIpsum',
        
    },
    {
        heading: 'faqH4',
        text: 'LoremIpsum',
        
    },


    
]